import React from 'react';

import { useInView } from 'react-intersection-observer';

import { fetchHttpAiredaleFeeds } from '../../../httpModules/airedale/feeds';
import { getAllVoucherOffersCount } from '../../../sharedModules/getAllVoucherOffersCount';
import { ClickType } from '../../../sharedModules/getClickType';
import { getWidgetInitialiser } from '../../../sharedModules/getWidgetInitialiser';
import { withHawkWidget, HawkWrapper } from '../../../sharedModules/withHawkWidget';
import { loadCSS } from '../../../utils/loadCSS';
import { getInitialiser } from '../../modules/getInitialiser';
import { sanitiseAiredaleStringParam } from '../../modules/setupModelWidget/getAirdaleInfo/sanitiseAiredaleStringParam';
import { GetInitialiserProps, WidgetWrapper } from '../../types/GetInitialiserProps';
import AffiliateLink from '../GenericComponents/AffiliateLink/AffiliateLink';
import { useHawkWidgetContext } from '../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import ScrollerArrow from '../GenericComponents/Scroller/ScrollerArrow';
import { VultureRevealContextProps } from '../GenericComponents/Vulture/RevealContext/types';

import styles from './scrollable-tile.css';
import { ScrollableTiles } from './ScrollableTiles';

loadCSS('scrollable-tile');

interface ScrollableTileProps extends VultureRevealContextProps {
  airdaleCategoryData: Record<string, any> | null;
}

const ScrollableTileMain: React.FC<ScrollableTileProps> = (props) => {
  const { airdaleCategoryData } = props;

  const { loadMoreDeals, widgetTitle, data, dealData } = useHawkWidgetContext();

  const { ref: firstTileRef, inView: firstTileInView } = useInView();
  const { ref: lastTileRef, inView: lastTileInView } = useInView();
  const scrollDistance = React.useRef(0);
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

  // Calculate the scroll distance based on the width of the grid items and the container
  React.useEffect(() => {
    const calculateScrollDistance = (): void => {
      if (scrollableContainerRef.current) {
        scrollDistance.current = scrollableContainerRef.current.offsetWidth;
      }
    };

    calculateScrollDistance(); // Calculate initial scroll distance

    window.addEventListener('resize', calculateScrollDistance);

    return () => {
      window.removeEventListener('resize', calculateScrollDistance);
    };
  }, []);

  React.useEffect(() => {
    if (lastTileInView) {
      loadMoreDeals(true);
    }
  }, [lastTileInView]);

  const handleScrollRight = (): void => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft += scrollDistance.current + 34;
    }
  };

  const handleScrollLeft = (): void => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft -= scrollDistance.current + 34;
    }
  };

  const numOffers = getAllVoucherOffersCount(data.counts);

  return (
    <div className={styles.container}>
      <h2 className={styles['widget-title']}>{widgetTitle}</h2>
      <div className={styles['arrow-container']}>
        <ScrollerArrow
          disabled={firstTileInView}
          onClick={handleScrollLeft}
          direction="left"
          externalClassName={`${styles['scroll-arrow']} ${styles['scroll-arrow-left']}`}
        />
        <div className={styles['scrollable-tile-container']} ref={scrollableContainerRef}>
          <ScrollableTiles
            firstTileRef={firstTileRef}
            lastTileRef={lastTileRef}
            dealData={dealData}
          />
        </div>
        <ScrollerArrow
          disabled={lastTileInView}
          onClick={handleScrollRight}
          direction="right"
          externalClassName={`${styles['scroll-arrow']} ${styles['scroll-arrow-right']}`}
        />
      </div>
      {Boolean(airdaleCategoryData?.canonical) && (
        <div className={styles['container-all-deals']}>
          <AffiliateLink clickType={ClickType.INTERNAL} customUrl={airdaleCategoryData?.canonical}>
            See all {numOffers} deals
          </AffiliateLink>
        </div>
      )}
    </div>
  );
};

ScrollableTileMain.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps },
  attributes,
  getJSON,
  editorial,
  defaultParams,
  endpoint,
  url,
  origin,
  dataLinkMerchant,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  if (attributes.modelName && site.vanillaName) {
    const airedaleData = (
      await fetchHttpAiredaleFeeds(
        {
          site: site.vanillaName,
          retailerCategory: `*${sanitiseAiredaleStringParam(attributes.modelName.substring(1))}*`,
          territory: props.territory,
        },
        getJSON,
      )
    ).body;

    const categoryData = airedaleData.find(
      (data) =>
        data.articlevoucherretailercategory.toLowerCase() === attributes.modelName?.toLowerCase(),
    );

    if (categoryData) {
      props.airdaleCategoryData = categoryData;
    }
  }

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export const ScrollableTile = withHawkWidget(
  ScrollableTileMain,
  HawkWrapper.VULTURE_REVEAL_CONTEXT,
);
